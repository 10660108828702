/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Kanit-Light';
    src: url("./assets/fonts/Kanit/Kanit-Light.ttf");
}

@font-face {
    font-family: 'Kanit-Bold';
    src: url("./assets/fonts/Kanit/Kanit-SemiBold.ttf");
}

@font-face {
    font-family: 'Kanit-SemiBold';
    src: url("./assets/fonts/Kanit/Kanit-SemiBold.ttf");
}


* {
    box-sizing: border-box;
}

body { 
    font-family: 'Kanit-Light', sans-serif;
    margin: 0px;
    padding: 0px;
}

h3 {
    font-family: 'Kanit-Bold', sans-serif;
}

ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

a {
    text-decoration: none;
}